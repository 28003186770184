import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { Module } from 'vuex';
import { nanoid } from 'nanoid';
import { router } from '@/router';
import { firestoreAction } from 'vuexfire';
import { PresenterPoll } from '@/api/interfaces.api';
import PresenterSession from '@/dtos/session';
import Vue from 'vue';
import ThemeModel from '@/dtos/theme';

export interface PresenterState {
  sessions: PresenterSession[];
  unlimitedSessions: PresenterSession[];
  shareURL?: string;
  currentSessionId?: string;
  currentSession?: PresenterSession;
  timelineData?: any[];
  sessionPolls: PresenterPoll[];
  sessionWordclouds: any[];
  sessionLabels: { [id: string]: string };
  sessionDescriptions: { [id: string]: string };
  sessionButtonLabels: { [id: string]: string };
  sessionThemes: ThemeModel[];
}

const PresenterModule: Module<PresenterState, any> = {
  namespaced: true,
  state: {
    sessions: [],
    unlimitedSessions: [],
    shareURL: '',
    currentSessionId: undefined,
    currentSession: undefined,
    timelineData: [],
    sessionPolls: [],
    sessionWordclouds: [],
    sessionLabels: {},
    sessionDescriptions: {},
    sessionButtonLabels: {},
    sessionThemes: [],
  } as PresenterState,
  getters: {
    getSessions: (state) => {
      if (state.sessions && state.sessions.length > 0) {
        const res = state.sessions.filter(
          (session: any) => !session.sessionUnlimited
        );
        return res;
      }
      return [];
    },
    getUnlimitedSessions: (state) => state.unlimitedSessions,
    getShareURL: (state) => state.shareURL,
    getCurrentSession: (state) => state.currentSession || {},
    getSessionShareToken: (state) => state.currentSession?.shareToken || '',
    getPastSessions: (state) => {
      if (state.sessions && state.sessions.length > 0) {
        const res = state.sessions.filter(
          (session: any) => !session.sessionUnlimited
        );
        return res;
      }
      return [];
    },
    getTimelineData: (state) => state.timelineData,
    getSessionPolls: (state) => state.sessionPolls,
    getSessionWordclouds: (state) => state.sessionWordclouds,
    getCurrentPoll: (state) => (pollId: string) =>
      state.sessionPolls.filter((poll: PresenterPoll) => poll.id === pollId),
    getLabels: (state) => state.sessionLabels,
    getDescriptions: (state) => state.sessionDescriptions,
    getButtonLabels: (state) => state.sessionButtonLabels,
    getSessionThemes: (state) =>
      state.sessionThemes.map((theme: ThemeModel) => theme.name),
  },
  mutations: {
    SET_SHARE_URL(state, payload) {
      state.shareURL = payload;
    },
    SET_CURRENT_SESSION_ID(state, payload) {
      state.currentSessionId = payload;
    },
    SET_CURRENT_SESSION(state, payload) {
      state.currentSession = payload;
    },
    SET_SESSION_LABEL(state, { label, labelId }) {
      Vue.set(state.sessionLabels, labelId, label);
    },
    REMOVE_SESSION_LABEL(state, labelId) {
      delete state.sessionLabels[labelId];
    },
    SET_SESSION_DESCRIPTION(state, { description, descriptionId }) {
      Vue.set(state.sessionDescriptions, descriptionId, description);
    },
    REMOVE_SESSION_DESCRIPTION(state, descriptionId) {
      delete state.sessionDescriptions[descriptionId];
    },
    SET_SESSION_BUTTON_LABEL(state, { buttonLabel, buttonId }) {
      Vue.set(state.sessionButtonLabels, buttonId, buttonLabel);
    },
    REMOVE_SESSION_BUTTON_LABEL(state, buttonId) {
      delete state.sessionButtonLabels[buttonId];
    },
    RESET(state) {
      state.sessions = [];
      state.unlimitedSessions = [];
      state.shareURL = '';
      state.currentSessionId = undefined;
      state.currentSession = undefined;
      state.timelineData = [];
      state.sessionPolls = [];
      state.sessionWordclouds = [];
      state.sessionLabels = {};
      state.sessionDescriptions = {};
      state.sessionButtonLabels = {};
    },
  },
  actions: {
    async updateDeactivatedWords({ getters }, { deactivatedWordsList }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        const ref = db.collection('sessions').doc(sessionId);
        ref.set({ deactivatedWordsList }, { merge: true });
      }
    },

    async updatePausedWordList({ getters }, { pausedWordsList }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        const ref = db.collection('sessions').doc(sessionId);
        ref.set({ pausedWordsList }, { merge: true });
      }
    },

    async createSession({ commit, rootGetters }, { form, callback }) {
      const {
        name,
        description,
        startAt,
        endAt,
        sessionUnlimited,
        theme,
      } = form;
      const userData = rootGetters['auth/getUser'];
      const { uid, displayName } = userData.data;
      const isSuperUser = !!userData.isSuperUser;
      const userGroup = isSuperUser ? theme : userData.group;
      const newSession = PresenterSession.fromDict({
        name,
        startAt,
        endAt,
        sessionUnlimited,
        link: '',
        description,
        author: uid,
        authorName: displayName,
        userGroup,
      });

      // Random generate nanoid for share URL
      let shareToken = nanoid(8);
      while (shareToken.includes('I') || shareToken.includes('l')) {
        shareToken = nanoid(8);
      }
      const shareURL =
        'https://' + window.location.host + '/join/' + shareToken;
      newSession.shareToken = shareToken;

      const req = { newSession: newSession.toJSON(), uid, shareURL };
      const res: Response = await fetch('/api/createSession', {
        method: 'post',
        body: JSON.stringify(req),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const resJSON = await res.json();

      commit('SET_SHARE_URL', shareURL);
      commit('SET_CURRENT_SESSION_ID', resJSON.id);
      commit('SET_CURRENT_SESSION', newSession);
      callback();
    },

    // duplicate here
    async duplicateSession(
      { commit, rootGetters, getters },
      { form, isViewerData, callback }
    ) {
      const oldSession = getters['getCurrentSession'];
      const oldSessionId = oldSession.id;

      const {
        name,
        description,
        startAt,
        endAt,
        sessionUnlimited,
        userGroup,
      } = form;
      const userData = rootGetters['auth/getUser'];
      const { uid, displayName } = userData.data;

      const newSession = PresenterSession.fromDict({
        name,
        startAt,
        endAt,
        sessionUnlimited,
        link: '',
        description,
        author: uid,
        authorName: displayName,
        userGroup,
      });
      if (isViewerData) {
        newSession.reactions = oldSession['reactions'];
        newSession.wordcloudTopic = oldSession['wordcloudTopic'];
      }

      // Random generate nanoid for share URL
      let shareToken = nanoid(8);
      while (shareToken.includes('I') || shareToken.includes('l')) {
        shareToken = nanoid(8);
      }
      const shareURL =
        'https://' + window.location.host + '/join/' + shareToken;
      newSession.shareToken = shareToken;
      const newSessionJSON = newSession.toJSON();

      const req = {
        newSession: newSessionJSON,
        oldSessionId,
        isViewerData,
        uid,
        shareURL,
      };
      const res: Response = await fetch('/api/duplicateSession', {
        method: 'post',
        body: JSON.stringify(req),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const resJSON = await res.json();

      commit('SET_SHARE_URL', shareURL);
      commit('SET_CURRENT_SESSION_ID', resJSON.id);
      commit('SET_CURRENT_SESSION', newSession);
      callback();
    },

    async uploadFile(_, { author, folderName, fileName, file, sessionId }) {
      // Upload file and metadata to the object
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef
        .child('session-images')
        .child(author)
        .child(sessionId)
        .child(folderName)
        .put(file);

      // Listen for state changes, errors, and completion of the upload.
      await uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            const db = firebase.firestore();
            db.collection('sessions')
              .doc(sessionId)
              .set({ [fileName]: downloadURL }, { merge: true });
          });
        }
      );
    },
    async updateSession({ getters }, form) {
      const {
        name,
        description,
        startAt,
        endAt,
        link,
        author,
        authorName,
        userGroup,
        shareToken,
        sessionUnlimited,
        theme,
        reactions,
        isPaused,
        deactivatedMenuItems,
        labels,
        descriptions,
        deactivatedWordsList,
      } = form;
      const updatedSession = PresenterSession.fromDict({
        name,
        startAt,
        endAt,
        sessionUnlimited,
        link,
        description,
        author,
        authorName,
        userGroup: theme ?? userGroup,
      });
      updatedSession.deactivatedMenuItems = deactivatedMenuItems;
      updatedSession.deactivatedWordsList = deactivatedWordsList;
      updatedSession.labels = labels;
      updatedSession.descriptions = descriptions;
      updatedSession.isPaused = isPaused;
      updatedSession.reactions = reactions;
      updatedSession.shareToken = shareToken;
      const sessionId = getters['getCurrentSession'].id;

      // TODO: Reset sessionSentReportStatus to pending

      // DB Initialization
      const db = firebase.firestore();
      const presentersCollection = db.collection('presenters');
      const sessionCollection = db.collection('sessions');

      if (sessionId) {
        await sessionCollection.doc(sessionId).update(updatedSession.toJSON());
        await presentersCollection
          .doc(author)
          .collection('sessions')
          .doc(sessionId)
          .update(updatedSession.toJSON());
      }
    },
    async updateDeactivatedItems({ getters }, { deactivatedMenuItems }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        const ref = db.collection('sessions').doc(sessionId);
        ref.set({ deactivatedMenuItems }, { merge: true });
      }
    },
    async checkForLastSession({ rootGetters }) {
      const user = rootGetters['auth/getUser'];
      if (user.data) {
        if (user.data.uid && !user.data.isAnonymous) {
          router.replace({ name: 'Presenter Home' });
        }
      }
    },
    async fetchPresenterSession({ dispatch, commit, rootGetters }, shareToken) {
      const db = firebase.firestore();
      const userId = rootGetters['auth/getUserId'];

      const sessionResponse = await db
        .collection('sessions')
        .where('shareToken', '==', shareToken)
        .where('author', '==', userId)
        .get();

      if (sessionResponse.empty) {
        // User has no access to the resource or resource does not exist
        commit('SET_CURRENT_SESSION', null);
        router.replace({ name: 'Presenter Home' });
      } else {
        const session = sessionResponse.docs[0].data();
        let sessionId = sessionResponse.docs[0].id;

        if (sessionId) {
          session.id = sessionId;
          commit('SET_CURRENT_SESSION', session);

          if (process.env.NODE_ENV === 'development') {
            console.log(sessionId);
          }

          dispatch('bindCurrentSession', sessionId);
          dispatch('bindPresenterPolls', { sessionId });
          dispatch('viewer/bindSessionViewers', { sessionId }, { root: true });
          dispatch('bindPresenterWordclouds', { sessionId });

          // Check if questions are connected with other session
          if (session.connectedSessionId) {
            sessionId = session.connectedSessionId;
          }
          dispatch('questions/bindQuestions', { sessionId }, { root: true });
          dispatch(
            'questions/bindQuestionColumns',
            { sessionId },
            { root: true }
          );
        }
      }
    },
    bindCurrentSession: firestoreAction(
      async ({ bindFirestoreRef }, sessionId) => {
        const db = firebase.firestore();
        const ref = db.collection('sessions').doc(sessionId);
        await bindFirestoreRef('currentSession', ref, {
          wait: true,
        });
      }
    ),
    bindAllSessionsRef: firestoreAction(
      async ({ bindFirestoreRef }, userId) => {
        const db = firebase.firestore();
        const ref = db.collection('sessions').where('author', '==', userId);
        await bindFirestoreRef('sessions', ref, {
          wait: true,
        });
      }
    ),
    bindUnlimitedSessionsRef: firestoreAction(
      async ({ bindFirestoreRef }, userId) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .where('author', '==', userId)
          .where('sessionUnlimited', '==', true)
          .orderBy('startAt', 'desc');
        await bindFirestoreRef('unlimitedSessions', ref, {
          wait: true,
        });
      }
    ),
    bindSessionsRef: firestoreAction(async ({ bindFirestoreRef }, userId) => {
      const db = firebase.firestore();
      const now = firebase.firestore.Timestamp.fromDate(new Date());
      const ref = db
        .collection('sessions')
        .where('author', '==', userId)
        .where('endAt', '>', now)
        .orderBy('endAt', 'desc')
        .orderBy('startAt', 'desc');
      await bindFirestoreRef('sessions', ref, {
        wait: true,
      });
    }),
    bindPastSessionsRef: firestoreAction(
      async ({ bindFirestoreRef }, userId) => {
        const db = firebase.firestore();
        const now = firebase.firestore.Timestamp.fromDate(new Date());
        const ref = db
          .collection('sessions')
          .where('author', '==', userId)
          .where('endAt', '<=', now)
          .orderBy('endAt', 'desc')
          .orderBy('startAt', 'desc');
        await bindFirestoreRef('sessions', ref, {
          wait: true,
        });
      }
    ),
    async cancelSession(_, sessionId) {
      // Stop current session
      const db = firebase.firestore();
      if (sessionId) {
        const sessionsCollection = db.collection('sessions');
        await sessionsCollection
          .doc(sessionId)
          .update({ endAt: new Date(), sessionUnlimited: false });
      }
      window.location.reload();
    },
    async removeSession({ rootGetters }, { sessionId, sessionPath }) {
      const db = firebase.firestore();
      const userId = rootGetters['auth/getUserId'];
      const presentersCollection = db.collection('presenters');
      const sessionsCollection = db.collection('sessions');

      await sessionsCollection.doc(sessionId).delete();
      await presentersCollection
        .doc(userId)
        .collection('sessions')
        .doc(sessionId)
        .delete();

      if (
        sessionPath !== '/presenter/home' &&
        sessionPath !== '/presenter/sessions/past'
      ) {
        router.push({ name: 'Presenter Home' });
      }
    },

    async pauseSession(_, { sessionId, state }) {
      // Stop current session
      const db = firebase.firestore();
      if (sessionId) {
        const sessionsCollection = db.collection('sessions');
        await sessionsCollection
          .doc(sessionId)
          .set({ isPaused: state }, { merge: true });
      }
    },
    async updateSessionLabel({ getters, commit }, { label, labelId }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ [`labels.${labelId}`]: label });
        commit('REMOVE_SESSION_LABEL', labelId);
      }
    },
    async updateSessionDescription(
      { getters, commit },
      { description, descriptionId }
    ) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ [`descriptions.${descriptionId}`]: description });
        commit('REMOVE_SESSION_DESCRIPTION', descriptionId);
      }
    },
    async updateSessionButtonLabel(
      { getters, commit },
      { buttonLabel, buttonId }
    ) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ [`buttonLabels.${buttonId}`]: buttonLabel });
        commit('REMOVE_SESSION_BUTTON_LABEL', buttonId);
      }
    },
    bindTimelineData: firestoreAction(
      async ({ bindFirestoreRef }, sessionId) => {
        const db = firebase.firestore();

        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('timeline');
        await bindFirestoreRef('timelineData', ref, {
          wait: true,
        });
      }
    ),
    /**************** Polls **********/
    bindPresenterPolls: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .orderBy('position', 'desc');
        await bindFirestoreRef('sessionPolls', ref, {
          wait: true,
        });
      }
    ),
    async createPoll({ rootGetters, getters }, { form, selectedStage }) {
      const { title, choices, textFieldPlaceholder } = form;
      let { type } = form;
      // Set to text field type if choices is empty
      if (choices.length === 0) {
        type = 2;
      }

      const db = firebase.firestore();
      const sessionId = getters['getCurrentSession'].id;
      const pollRef = db
        .collection('sessions')
        .doc(sessionId)
        .collection('polls');

      const sessionPolls = getters['getSessionPolls'];
      const lastPollPosition =
        sessionPolls.length > 0 ? sessionPolls.length : 0;

      const { uid } = rootGetters['auth/getUser'].data;
      const pollCount = (await pollRef.get()).size;

      const newPoll = PresenterPoll.fromDict({
        title,
        type,
        choices,
        author: uid,
        textFieldPlaceholder,
        isActive: false,
        initialPosition: pollCount + 1,
        position: lastPollPosition,
      });

      if (selectedStage) {
        newPoll.stage = selectedStage;
      }

      if (sessionId) {
        pollRef.add(newPoll.toJSON());
      }
    },
    async updatePoll(
      { rootGetters, getters },
      { form, pollId, selectedStage }
    ) {
      const {
        title,
        choices,
        isActive,
        textFieldPlaceholder,
        position,
        initialPosition,
      } = form;
      let { type } = form;
      if (choices.length === 0) {
        type = 2;
      }
      const { uid } = rootGetters['auth/getUser'].data;
      const updatedPoll = PresenterPoll.fromDict({
        title,
        type,
        choices,
        author: uid,
        textFieldPlaceholder,
        isActive,
        initialPosition,
        position,
      });

      if (selectedStage) {
        updatedPoll.stage = selectedStage;
      }

      const db = firebase.firestore();
      const sessionId = getters['getCurrentSession'].id;

      if (sessionId && pollId) {
        db.collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .update(updatedPoll.toJSON());
      }
    },
    async resetPoll({ getters }, { pollId }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        const pollRef = db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId);
        await pollRef.update({ answers: {} });

        // Delete all user IPs for poll
        await pollRef
          .collection('userIPs')
          .get()
          .then((querySnapshot) => {
            querySnapshot.docs.forEach(async (snapshot) => {
              snapshot.ref.delete();
            });
          });
      }
    },
    async removePoll({ getters }, { pollId }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .delete();
      }
    },
    async togglePollVisibility({ getters }, { pollId, state }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .update({ isActive: !!state });
      }
    },
    async toggleStageVisibility({ getters }, { stageId, state }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ [`stages.${stageId}.visible`]: state });
      }
    },
    async togglePollStatisticsVisibility({ getters }, { pollId, state }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .update({ showPollStatistics: !!state });
      }
    },
    async togglePollPaused({ getters }, { pollId, state }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .update({ isPaused: !!state });
      }
    },

    async toggleViewerPieChart({ getters }, { pollId, state }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .doc(pollId)
          .update({ isViewerPieChartVisible: !!state });
      }
    },

    async updatePollPosition({ getters }, newPollList) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();

        const count = newPollList.length;
        await newPollList.map(async (poll: PresenterPoll, i: number) => {
          await db
            .collection('sessions')
            .doc(sessionId)
            .collection('polls')
            .doc(poll.id)
            .update({ position: count - i });
        });
      }
    },
    async togglePollLabelState({ getters }, state) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ hidePollLabels: state });
      }
    },
    async updatePollVoteResultType({ getters }, id) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ voteResultType: id });
      }
    },
    /**************** Wordclouds **********/
    bindPresenterWordclouds: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('wordclouds');
        await bindFirestoreRef('sessionWordclouds', ref, {
          wait: true,
        });
      }
    ),
    async toggleWordcloudVisibility({ getters }, { state }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ isWordcloudActive: !!state });
      }
    },
    async toggleWordcloudPaused({ getters }, { state }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ isWordcloudPaused: !!state });
      }
    },
    async resetWordcloud({ getters }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        const newWordcloudId = await db
          .collection('sessions')
          .doc(sessionId)
          .collection('wordclouds')
          .add({});
        if (newWordcloudId.id) {
          await db
            .collection('sessions')
            .doc(sessionId)
            .update({ activeWordcloud: newWordcloudId.id });
        }
      }
    },
    async updateWordcloudTopic({ getters }, { topic }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ wordcloudTopic: topic });
      }
    },
    /**************** TV Mode **********/
    async fetchTVSession({ dispatch, commit }, shareToken) {
      const db = firebase.firestore();
      const sessionResponse = await db
        .collection('sessions')
        .where('shareToken', '==', shareToken)
        .get();
      const session = sessionResponse.docs[0].data();
      const sessionId = sessionResponse.docs[0].id;

      if (sessionId) {
        session.id = sessionId;
        commit('SET_CURRENT_SESSION', session);

        dispatch('bindCurrentSession', sessionId);
        dispatch(
          'viewer/fetchSessionTheme',
          {
            sessionUserGroup: session.userGroup,
          },
          { root: true }
        );
        dispatch(
          'viewer/bindSessionViewers',
          {
            sessionId,
          },
          { root: true }
        );
        dispatch(
          'questions/bindQuestions',
          {
            sessionId,
          },
          { root: true }
        );
        dispatch(
          'questions/bindQuestionColumns',
          { sessionId },
          { root: true }
        );
        dispatch('bindPresenterPolls', {
          sessionId,
        });
        dispatch('bindPresenterWordclouds', {
          sessionId,
        });
      }
    },
    async activateTVMode({ getters }, { tvMode, state }) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        if (state) {
          await db
            .collection('sessions')
            .doc(sessionId)
            .set({ tv: tvMode }, { merge: true });
        } else {
          await db
            .collection('sessions')
            .doc(sessionId)
            .set({ tv: '' }, { merge: true });
        }
      }
    },
    async toggleGreenscreenVisibilitySetting({ getters }, state) {
      const sessionId = getters['getCurrentSession'].id;
      if (sessionId) {
        const db = firebase.firestore();
        await db
          .collection('sessions')
          .doc(sessionId)
          .update({ showGreenscreen: !!state });
      }
    },
    /**************** Session Themes Section **********/
    bindSessionThemes: firestoreAction(async ({ bindFirestoreRef }) => {
      const db = firebase.firestore();
      const ref = db.collection('themes');
      await bindFirestoreRef('sessionThemes', ref, {
        wait: true,
      });
    }),
  },
};

export { PresenterModule };
