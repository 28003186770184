/* eslint @typescript-eslint/camelcase: 0 */
interface UserCredentials {
  username: string;
  password: string;
}

class ViewerQuestion {
  public id: string;
  public title: string;
  public position: number;
  public createdAt: Date;
  public updatedAt: Date;
  public author: string;
  public upvotes: number;
  public isVisible: boolean;
  public initialPosition: number;
  public answer: string;
  public isSelected: boolean;
  public isAnswered: boolean;
  public isBookmarked: boolean;
  public questionerName: string;
  public columnId: string;

  constructor(
    id: string,
    title: string,
    position: number,
    createdAt: Date,
    updatedAt: Date,
    author: string,
    upvotes: number,
    isVisible: boolean,
    initialPosition: number,
    answer: string,
    isSelected: boolean,
    isAnswered: boolean,
    isBookmarked: boolean,
    questionerName: string,
    columnId: string
  ) {
    this.id = id;
    this.position = position;
    this.title = title;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.author = author;
    this.upvotes = upvotes;
    this.isVisible = isVisible;
    this.initialPosition = initialPosition;
    this.answer = answer;
    this.isSelected = isSelected;
    this.isAnswered = isAnswered;
    this.isBookmarked = isBookmarked;
    this.questionerName = questionerName;
    this.columnId = columnId;
  }

  static fromDict(dict: any): ViewerQuestion {
    const {
      title,
      position,
      initialPosition,
      author,
      isVisible,
      questionerName,
    } = dict;

    return new ViewerQuestion(
      '',
      title,
      position,
      new Date(),
      new Date(),
      author,
      0,
      isVisible,
      initialPosition,
      '',
      false,
      false,
      false,
      questionerName || '',
      'default'
    );
  }

  toJSON(): any {
    return {
      position: this.position,
      title: this.title,
      updatedAt: this.updatedAt,
      createdAt: this.createdAt,
      author: this.author,
      upvotes: this.upvotes,
      isVisible: this.isVisible,
      initialPosition: this.initialPosition,
      answer: this.answer,
      isSelected: this.isSelected,
      isAnswered: this.isAnswered,
      isBookmarked: this.isBookmarked,
      questionerName: this.questionerName,
      columnId: this.columnId,
    };
  }
}

enum PollType {
  SingleChoice = 0,
  MultipleChocie = 1,
  TextField = 2,
  Slider = 3,
}

class PresenterPoll {
  public id: string;
  public title: string;
  public type: PollType;
  public createdAt: Date;
  public updatedAt: Date;
  public author: string;
  public choices: string[];
  public answers: any;
  public isActive: boolean;
  public textFieldPlaceholder: string;
  public initialPosition: number;
  public position: number;
  public isPaused: boolean;
  public isViewerPieChartVisible: boolean;
  public showPollStatistics: boolean;
  public stage?: string;

  constructor(
    id: string,
    title: string,
    type: PollType,
    createdAt: Date,
    updatedAt: Date,
    author: string,
    choices: string[],
    answers: any,
    isActive: boolean,
    textFieldPlaceholder: string,
    initialPosition: number,
    position: number,
    isPaused: boolean,
    isViewerPieChartVisible: boolean,
    showPollStatistics: boolean
  ) {
    this.id = id;
    this.title = title;
    this.type = type;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.author = author;
    this.choices = choices;
    this.answers = answers;
    this.isActive = isActive;
    this.textFieldPlaceholder = textFieldPlaceholder;
    this.initialPosition = initialPosition;
    this.position = position;
    this.isPaused = isPaused;
    this.isViewerPieChartVisible = isViewerPieChartVisible;
    this.showPollStatistics = showPollStatistics;
  }

  static fromDict(dict: any): PresenterPoll {
    const {
      title,
      type,
      author,
      choices,
      textFieldPlaceholder,
      isActive,
      initialPosition,
      position,
      isViewerPieChartVisible,
      showPollStatistics,
    } = dict;

    return new PresenterPoll(
      '',
      title,
      type,
      new Date(),
      new Date(),
      author,
      choices,
      {},
      isActive ?? false,
      textFieldPlaceholder,
      initialPosition,
      position,
      false,
      isViewerPieChartVisible ?? false,
      showPollStatistics ?? false
    );
  }

  toJSON(): any {
    return {
      title: this.title,
      type: this.type,
      updatedAt: this.updatedAt,
      createdAt: this.createdAt,
      author: this.author,
      choices: this.choices,
      answers: this.answers,
      isActive: this.isActive,
      textFieldPlaceholder: this.textFieldPlaceholder,
      initialPosition: this.initialPosition,
      position: this.position,
      isPaused: this.isPaused,
      isViewerPieChartVisible: this.isViewerPieChartVisible,
      showPollStatistics: this.showPollStatistics,
      stage: this.stage ? this.stage : null,
    };
  }
}

export { UserCredentials, ViewerQuestion, PresenterPoll, PollType };
