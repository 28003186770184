import { Module } from 'vuex';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { router } from '@/router';
import { firestoreAction } from 'vuexfire';
import { PresenterPoll, ViewerQuestion } from '@/api/interfaces.api';
import { incrementReactionCount } from '@/utils/tools';
import ThemeModel from '@/dtos/theme';
import PresenterSession from '@/dtos/session';

export interface ViewerState {
  isSessionValid: boolean;
  currentSession?: PresenterSession;
  currentSessionId?: string;
  sessionViewers?: any;
  sessionQuestions: ViewerQuestion[];
  sessionPolls: PresenterPoll[];
  sessionWordclouds: any[];
  sessionTheme: ThemeModel;
  questionCount: number;
  questionLastViewedCount: number;
  pollCount: number;
  pollLastViewedCount: number;
  hideHeader?: boolean;
  hideReactionMenuItem?: boolean;
  userAnsweredPollIds: string[];
}

const ViewerModule: Module<ViewerState, any> = {
  namespaced: true,
  state: {
    isSessionValid: false,
    currentSession: undefined,
    currentSessionId: undefined,
    sessionViewers: undefined,
    sessionQuestions: [],
    sessionPolls: [],
    sessionWordclouds: [],
    sessionTheme: ThemeModel.defaultTheme(),
    questionCount: 0,
    questionLastViewedCount: 0,
    pollCount: 0,
    pollLastViewedCount: 0,
    hideHeader: undefined,
    hideReactionMenuItem: undefined,
    userAnsweredPollIds: [],
  } as ViewerState,
  getters: {
    isSessionValid(state) {
      return state.isSessionValid;
    },
    getActiveSession: (state) => state.currentSession || null,
    getSessionViewers: (state) => state.sessionViewers || null,
    getSessionQuestions: (state) => (uid: string) =>
      state.sessionQuestions.filter(
        (question) => question.isVisible || question.author === uid
      ),
    getSessionPolls: (state) => state.sessionPolls,
    getSessionWordclouds: (state) => state.sessionWordclouds,
    getSessionTheme: (state) => state.sessionTheme,
    getNewQuestionCount: (state) => {
      return state.questionCount - state.questionLastViewedCount;
    },
    getNewPollCount: (state) => {
      return state.pollCount - state.pollLastViewedCount;
    },
    getHideHeader: (state) => {
      return state.hideHeader;
    },
    getHideReactionMenuItem: (state) => {
      return state.hideReactionMenuItem;
    },
    getAnsweredPollIds: (state) => state.userAnsweredPollIds,
  },
  mutations: {
    SET_HIDE_HEADER(state, value) {
      state.hideHeader = value;
    },
    SET_HIDE_REACTIONS(state, value) {
      state.hideReactionMenuItem = value;
    },
    SET_SESSION_VALID(state, payload) {
      state.isSessionValid = payload;
    },
    SET_CURRENT_SESSION_ID(state, { id }) {
      state.currentSessionId = id;
    },
    SET_SESSION_THEME(state, payload) {
      state.sessionTheme = payload;
    },

    /**
     * Question states
     */
    ADD_QUESTION_COUNT(state) {
      state.questionCount++;
    },
    INIT_QUESTION_LASTVIEWED_COUNT(state, value) {
      state.questionLastViewedCount = value;
    },
    RESET_QUESTION_COUNT(state) {
      state.questionLastViewedCount = state.questionCount;
    },
    /**
     * Poll states
     */
    ADD_POLL_COUNT(state) {
      state.pollCount++;
    },
    SET_USER_ANSWERED_POLL_ID(state, userAnsweredPollIds) {
      state.userAnsweredPollIds = userAnsweredPollIds;
    },
    INIT_POLL_LASTVIEWED_COUNT(state, value) {
      state.pollLastViewedCount = value;
    },
    RESET_POLL_COUNT(state) {
      state.pollLastViewedCount = state.pollCount;
    },

    RESET(state) {
      state.isSessionValid = false;
      state.currentSession = undefined;
      state.currentSessionId = undefined;
      state.sessionViewers = undefined;
      state.sessionQuestions = [];
      state.sessionPolls = [];
      state.sessionWordclouds = [];
      state.sessionTheme = ThemeModel.defaultTheme();
      state.questionLastViewedCount = 0;
      state.questionCount = 0;
      state.pollLastViewedCount = 0;
      state.pollCount = 0;
      state.userAnsweredPollIds = [];
    },
  },
  actions: {
    setHideHeader({ commit }, { value }) {
      commit('SET_HIDE_HEADER', value);
    },

    setHideReactions({ commit }, { value }) {
      commit('SET_HIDE_REACTIONS', value);
    },

    resetQuestionCount({ commit }) {
      commit('RESET_QUESTION_COUNT');
    },

    resetPollCount({ commit }) {
      commit('RESET_POLL_COUNT');
    },

    async isSessionValid({ commit, dispatch }, { sessionToken, goToSession }) {
      if (sessionToken) {
        const db = firebase.firestore();
        const sessionCollection = db.collection('sessions');
        const data = await sessionCollection
          .where('shareToken', '==', sessionToken)
          .get();

        if (data) {
          commit('SET_SESSION_VALID', !!data.docs.length);
          if (data.docs.length) {
            commit('SET_CURRENT_SESSION_ID', {
              id: data.docs[0].id,
            });
            const sessionId = data.docs[0].id;
            await dispatch('bindSessionRef', { sessionId });
            if (goToSession)
              router.replace({
                name: 'Viewer Session',
                params: { sessionId: sessionToken },
              });
          }
        }
      }
    },
    async fetchSessionTheme({ commit }, { sessionUserGroup }) {
      const db = firebase.firestore();
      const themeRef = db
        .collection('themes')
        .doc(sessionUserGroup || 'reaction.link');

      const theme = await themeRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            return ThemeModel.fromDict(doc.data());
          } else {
            return ThemeModel.defaultTheme();
          }
        })
        .catch(function (error) {
          console.log('Error getting document:', error);
          return ThemeModel.defaultTheme();
        });

      if (theme) {
        commit('SET_SESSION_THEME', theme);
      } else {
        console.log('No such document!');
      }
    },
    bindSessionRef: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db.collection('sessions').doc(sessionId);
        await bindFirestoreRef('currentSession', ref, {
          wait: true,
        });
      }
    ),
    bindSessionViewers: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('viewers');
        await bindFirestoreRef('sessionViewers', ref, {
          wait: true,
        });
      }
    ),

    submitReaction(
      { state, dispatch, rootGetters },
      { reactionName, currentMin }
    ) {
      const sessionId = state.currentSession ? state.currentSession.id : null;

      if (sessionId && reactionName) {
        const db = firebase.firestore();
        const sessionRef = db.collection('sessions').doc(sessionId);
        db.runTransaction((transaction) => {
          return transaction.get(sessionRef).then((doc: any) => {
            if (!doc.exists) {
              throw 'Document does not exist!';
            }
            let reactions = doc.data().reactions;
            reactions = incrementReactionCount(reactions, reactionName);

            transaction.update(sessionRef, { reactions });
          });
        });
        const viewerId = rootGetters['auth/getUserId'];
        if (viewerId) {
          const viewerRef = sessionRef.collection('viewers').doc(viewerId);
          db.runTransaction((transaction) => {
            return transaction.get(viewerRef).then((doc: any) => {
              let reactionsCount = 0;
              if (doc.exists) {
                reactionsCount = doc.data().reactionsCount;
              }
              reactionsCount += 1;

              transaction.set(viewerRef, { reactionsCount }, { merge: true });
            });
          });
        }
        if (currentMin) {
          dispatch('updateTimeline', { sessionId, currentMin, reactionName });
        }
      }
    },
    updateTimeline(_, { sessionId, currentMin, reactionName }) {
      const db = firebase.firestore();
      const timelineRef = db
        .collection('sessions')
        .doc(sessionId)
        .collection('timeline')
        .doc(String(currentMin));
      // TODO: Fix bug that occurred when reacting with claps too many times in a minutes (threshold around 10)
      db.runTransaction((transaction) => {
        return transaction.get(timelineRef).then((doc: any) => {
          let reactions = {
            thumbsUpCount: 0,
            thumbsDownCount: 0,
            heartCount: 0,
            funEmojiCount: 0,
            sadEmojiCount: 0,
            clapsCount: 0,
            rocketCount: 0,
          };
          if (doc.exists) {
            reactions = doc.data().reactions;
            reactions = incrementReactionCount(reactions, reactionName);
            transaction.update(timelineRef, { reactions });
          } else {
            reactions = incrementReactionCount(reactions, reactionName);
            transaction.set(timelineRef, { reactions }, { merge: true });
          }
        });
      });
    },
    async submitReview(_, { rating, message, sessionId }) {
      if (rating && message && sessionId) {
        const db = firebase.firestore();
        const sessionCollection = db.collection('sessions');
        try {
          await sessionCollection.doc(sessionId).collection('reviews').add({
            rating,
            message,
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    /********* Viewer Questions ************/
    bindSessionQuestions: firestoreAction(
      async ({ commit, bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('questions')
          .orderBy('position', 'desc');

        // Disable if on reload should show badge with all questions
        const visibleQuestions = (
          await ref.where('isVisible', '==', true).get()
        ).docs.length;

        commit('INIT_QUESTION_LASTVIEWED_COUNT', visibleQuestions);

        await bindFirestoreRef('sessionQuestions', ref, {
          wait: true,
          serialize: (doc: any) => {
            const data = doc.data();

            if (data.isVisible === true) {
              commit('ADD_QUESTION_COUNT');
            }

            return {
              id: doc.id,
              title: data.title,
              position: data.position,
              createdAt: data.createdAt,
              updatedAt: data.updatedAt,
              author: data.author,
              upvotes: data.upvotes,
              isVisible: data.isVisible,
              initialPosition: data.initialPosition,
              answer: data.answer,
              isSelected: data.isSelected,
              isAnswered: data.isAnswered,
              isBookmarked: data.isBookmarked,
              questionerName: data.questionName,
              columnId: data.columnId,
            };
          },
        });
      }
    ),

    async submitQuestion({ rootGetters, getters, state }, { title, name }) {
      let sessionId = null;
      if (state.currentSession) {
        sessionId = state.currentSession.connectedSessionId
          ? state.currentSession.connectedSessionId
          : state.currentSession.id;
      }

      if (sessionId) {
        const db = firebase.firestore();
        const author = rootGetters['auth/getUserId'];
        const questionsRef = db
          .collection('sessions')
          .doc(sessionId)
          .collection('questions');
        const questionCountRef = db.collection('sessions').doc(sessionId);

        const sessionQuestions = getters['getSessionQuestions'](author);
        let lastQuestionPosition = 0;
        if (sessionQuestions.length > 0) {
          lastQuestionPosition = sessionQuestions[0].position;
        }

        if (name && name.replace(/\s/g, '').length) {
          localStorage.setItem('username', name);
        }

        const question = ViewerQuestion.fromDict({
          title,
          position: lastQuestionPosition + 1,
          initialPosition: 0,
          author,
          isVisible: false,
          questionerName:
            name && name.replace(/\s/g, '').length ? name : 'anonym',
        });

        const questionId = await questionsRef.add(question.toJSON());

        db.runTransaction((transaction) => {
          return transaction.get(questionCountRef).then((doc: any) => {
            if (!doc.exists) {
              throw 'Document does not exist!';
            }

            let questionsCount = 1;
            if (doc.data().questionsCount) {
              questionsCount = doc.data().questionsCount + 1;
            }

            questionsRef.doc(questionId.id).update({
              isVisible: !state.currentSession?.hideAllIncomingQuestions,
              initialPosition: isNaN(questionsCount) ? 1 : questionsCount,
            });

            transaction.update(questionCountRef, { questionsCount });
          });
        });

        router.push({ name: 'Viewer Session Questions' });
      }
    },
    async submitQuestionUpvote({ state }, questionId) {
      let sessionId = null;
      if (state.currentSession) {
        sessionId = state.currentSession.connectedSessionId
          ? state.currentSession.connectedSessionId
          : state.currentSession.id;
      }

      if (sessionId && questionId) {
        const db = firebase.firestore();
        const questionRef = db
          .collection('sessions')
          .doc(sessionId)
          .collection('questions')
          .doc(questionId);
        db.runTransaction((transaction) => {
          return transaction.get(questionRef).then((doc: any) => {
            if (!doc.exists) {
              throw 'Document does not exist!';
            }
            const upvotes = doc.data().upvotes + 1;

            transaction.update(questionRef, { upvotes });
          });
        });
      }
    },
    /********* Polls ************/
    bindSessionPolls: firestoreAction(
      async ({ commit, bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls')
          .where('isActive', '==', true)
          .orderBy('position', 'desc');

        // Disable if on reload should show badge with all polls
        const visiblePolls = (await ref.get()).docs.length;
        commit('INIT_POLL_LASTVIEWED_COUNT', visiblePolls);

        await bindFirestoreRef('sessionPolls', ref, {
          wait: true,
          serialize: (doc: any) => {
            const data = doc.data();
            if (data.isActive === true) {
              commit('ADD_POLL_COUNT');
            }

            return {
              id: doc.id,
              title: data.title,
              type: data.type,
              createdAt: data.createdAt,
              updatedAt: data.updatedAt,
              author: data.author,
              choices: data.choices,
              answers: data.answers,
              isActive: data.isActive,
              textFieldPlaceholder: data.textFieldPlaceholder,
              initialPosition: data.initialPosition,
              position: data.position,
              isPaused: data.isPaused,
              isViewerPieChartVisible: data.isViewerPieChartVisible,
              showPollStatistics: data.showPollStatistics,
              stage: data.stage,
            };
          },
        });
      }
    ),
    async fetchPollsUserIPs({ state, commit }) {
      const currentSession = state.currentSession;
      const sessionId = currentSession ? currentSession.id : null;

      const userIPReq = await fetch('https://api.ipify.org?format=json');
      const userIPRes = await userIPReq.json();
      const userIP = userIPRes.ip;

      if (sessionId && userIP) {
        const db = firebase.firestore();
        const pollsRef = db
          .collection('sessions')
          .doc(sessionId)
          .collection('polls');

        const res: string[] = [];
        await pollsRef.get().then(async (querySnapshot) => {
          for (const snapshot of querySnapshot.docs) {
            const pollUser = await snapshot.ref
              .collection('userIPs')
              .doc(userIP)
              .get();
            if (pollUser.exists) {
              res.push(snapshot.id);
            }
          }
        });

        commit('SET_USER_ANSWERED_POLL_ID', res);
      }
    },
    async submitPollAnswer(
      { state, rootGetters, dispatch },
      { choices, pollId }
    ) {
      const currentSession = state.currentSession;
      const sessionId = currentSession ? currentSession.id : null;
      const userGroup = currentSession ? currentSession.userGroup : null;
      const viewerId = rootGetters['auth/getUserId'];

      if (choices.length === 0) {
        return;
      }

      if (sessionId && pollId) {
        if (userGroup && userGroup === 'prematch') {
          const userIPReq = await fetch('https://api.ipify.org?format=json');
          const userIPRes = await userIPReq.json();
          const userIP = userIPRes.ip;

          if (userIP) {
            const db = firebase.firestore();
            const pollRef = db
              .collection('sessions')
              .doc(sessionId)
              .collection('polls')
              .doc(pollId);

            if (currentSession?.requireViewerName) {
              if (typeof choices[0] === 'string') {
                const username = localStorage.getItem('username');
                choices[0] += ` von ${username}`;
              }
            }

            const ipData = await pollRef
              .collection('userIPs')
              .doc(userIP)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  return doc.data();
                } else {
                  return null;
                }
              })
              .catch((error) => {
                console.log('Error getting document:', error);
                return null;
              });

            if (!(ipData && ipData[userIP] === pollId)) {
              db.runTransaction((transaction) => {
                return transaction.get(pollRef).then((doc: any) => {
                  if (!doc.exists) {
                    throw 'Document does not exist!';
                  }

                  transaction.set(
                    pollRef,
                    {
                      answers: {
                        [viewerId]: choices,
                      },
                    },
                    { merge: true }
                  );
                });
              });
            }

            await pollRef
              .collection('userIPs')
              .doc(userIP)
              .set({ [userIP]: pollId });

            dispatch('fetchPollsUserIPs');
          }
        } else {
          const db = firebase.firestore();
          const pollRef = db
            .collection('sessions')
            .doc(sessionId)
            .collection('polls')
            .doc(pollId);

          if (currentSession?.requireViewerName) {
            if (typeof choices[0] === 'string') {
              const username = localStorage.getItem('username');
              choices[0] += ` von ${username}`;
            }
          }

          db.runTransaction((transaction) => {
            return transaction.get(pollRef).then((doc: any) => {
              if (!doc.exists) {
                throw 'Document does not exist!';
              }

              transaction.set(
                pollRef,
                {
                  answers: {
                    [viewerId]: choices,
                  },
                },
                { merge: true }
              );
            });
          });
        }
      }
    },
    /**************** Wordclouds **********/
    bindSessionWordcloud: firestoreAction(
      async ({ bindFirestoreRef }, { sessionId }) => {
        const db = firebase.firestore();
        const ref = db
          .collection('sessions')
          .doc(sessionId)
          .collection('wordclouds');
        await bindFirestoreRef('sessionWordclouds', ref, {
          wait: true,
        });
      }
    ),
    async submitWord({ state }, { word, wordcloudId, pausedWordsList }) {
      const sessionId = state.currentSession ? state.currentSession.id : null;

      if (sessionId) {
        const db = firebase.firestore();

        const wordcloudRef = db
          .collection('sessions')
          .doc(sessionId)
          .collection('wordclouds')
          .doc(wordcloudId);

        db.runTransaction((transaction) => {
          return transaction.get(wordcloudRef).then((doc: any) => {
            if (!doc.exists) {
              throw 'Document does not exist!';
            }

            //check if key is in pausedList
            if (
              pausedWordsList &&
              pausedWordsList.find((obj: any) =>
                Object.keys(obj).includes(word)
              )
            ) {
              transaction.update(wordcloudRef, {
                [word]: doc.data()[word],
              });
              //check if key in data
            } else if (word in doc.data()) {
              transaction.update(wordcloudRef, {
                [word]: doc.data()[word] + 1,
              });
            } else {
              transaction.set(
                wordcloudRef,
                {
                  [word]: 1,
                },
                { merge: true }
              );
            }
          });
        });
      }
    },
  },
};

export { ViewerModule };
