import * as firebase from 'firebase/app';
import 'firebase/firestore';

interface PausedWord {
  [key: string]: number;
}
class PresenterSession implements SessionDTO {
  public id: string;
  public name: string;
  public color: string | null;
  public startAt: firebase.firestore.Timestamp;
  public endAt: firebase.firestore.Timestamp;
  public sessionUnlimited: boolean;
  public link: string | null;
  public description: string | null;
  public createdAt: Date;
  public updatedAt: Date;
  public author: string;
  public authorName: string;
  public userGroup: string;
  public shareToken: string;
  public reactions: any;
  public isPaused: boolean;
  public deactivatedMenuItems: string[];
  public qrCodeImageURL: string;
  public labels: { [id: string]: string };
  public descriptions: { [id: string]: string };
  public hideAllIncomingQuestions: boolean;
  public voteResultType: number;
  public hidePollLabels: boolean;
  public questionsCount: number;
  public showQuestionsUserNameInput: boolean;
  public hideQuestionLikes: boolean;
  public wordcloudTopic: string;
  public activeWordcloud: string;
  public tv: string;
  public moderatorMode: string[];
  public requireViewerName: boolean;
  public deactivatedWordsList: string[];
  public pausedWordsList: PausedWord[];
  public connectedSessionId?: string;

  constructor(
    id: string,
    name: string,
    color: string | null,
    startAt: firebase.firestore.Timestamp,
    endAt: firebase.firestore.Timestamp,
    sessionUnlimited: boolean,
    link: string | null,
    description: string | null,
    createdAt: Date,
    updatedAt: Date,
    author: string,
    authorName: string,
    userGroup: string,
    shareToken: string,
    reactions: any,
    isPaused: boolean,
    deactivatedMenuItems: string[],
    qrCodeImageURL: string,
    labels: { [id: string]: string },
    descriptions: { [id: string]: string },
    hideAllIncomingQuestions: boolean,
    voteResultType: number,
    hidePollLabels: boolean,
    questionsCount: number,
    showQuestionsUserNameInput: boolean,
    hideQuestionLikes: boolean,
    wordcloudTopic: string,
    activeWordcloud: string,
    tv: string,
    moderatorMode: string[],
    requireViewerName: boolean,
    deactivatedWordsList: string[],
    pausedWordsList: PausedWord[]
  ) {
    this.id = id;
    this.name = name;
    this.color = color;
    this.startAt = startAt;
    this.endAt = endAt;
    this.sessionUnlimited = sessionUnlimited;
    this.link = link;
    this.description = description;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.author = author;
    this.authorName = authorName;
    this.userGroup = userGroup;
    this.shareToken = shareToken;
    this.reactions = reactions;
    this.isPaused = isPaused;
    this.deactivatedMenuItems = deactivatedMenuItems;
    this.qrCodeImageURL = qrCodeImageURL;
    this.labels = labels;
    this.descriptions = descriptions;
    this.hideAllIncomingQuestions = hideAllIncomingQuestions;
    this.voteResultType = voteResultType;
    this.hidePollLabels = hidePollLabels;
    this.questionsCount = questionsCount;
    this.showQuestionsUserNameInput = showQuestionsUserNameInput;
    this.hideQuestionLikes = hideQuestionLikes;
    this.wordcloudTopic = wordcloudTopic;
    this.activeWordcloud = activeWordcloud;
    this.tv = tv;
    this.moderatorMode = moderatorMode;
    this.requireViewerName = requireViewerName;
    this.deactivatedWordsList = deactivatedWordsList;
    this.pausedWordsList = pausedWordsList;
  }

  static fromDict(dict: any): PresenterSession {
    const {
      name,
      startAt,
      endAt,
      sessionUnlimited,
      link,
      description,
      author,
      authorName,
      userGroup,
      activeWordcloud,
    } = dict;

    return new PresenterSession(
      '',
      name,
      null,
      firebase.firestore.Timestamp.fromDate(startAt),
      firebase.firestore.Timestamp.fromDate(endAt),
      sessionUnlimited,
      link || '',
      description || '',
      new Date(),
      new Date(),
      author,
      authorName,
      userGroup,
      '',
      {
        thumbsUpCount: 0,
        thumbsDownCount: 0,
        heartCount: 0,
        funEmojiCount: 0,
        sadEmojiCount: 0,
        clapsCount: 0,
        rocketCount: 0,
      },
      false,
      [],
      '',
      {},
      {},
      false,
      0,
      false,
      0,
      false,
      false,
      '',
      activeWordcloud || '',
      '',
      [],
      false,
      [],
      []
    );
  }

  toJSON(): any {
    return {
      name: this.name,
      color: this.color,
      startAt: this.startAt.toDate(),
      endAt: this.endAt.toDate(),
      sessionUnlimited: this.sessionUnlimited,
      link: this.link,
      description: this.description,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      author: this.author,
      authorName: this.authorName,
      userGroup: this.userGroup,
      shareToken: this.shareToken,
      reactions: this.reactions,
      isPaused: this.isPaused,
      deactivatedMenuItems: this.deactivatedMenuItems,
      labels: this.labels,
      descriptions: this.descriptions,
      hideAllIncomingQuestions: this.hideAllIncomingQuestions,
      voteResultType: this.voteResultType,
      hidePollLabels: this.hidePollLabels,
      questionsCount: this.questionsCount,
      showQuestionsUserNameInput: this.showQuestionsUserNameInput,
      hideQuestionLikes: this.hideQuestionLikes,
      wordcloudTopic: this.wordcloudTopic,
      activeWordcloud: this.activeWordcloud,
      tv: this.tv,
      moderatorMode: this.moderatorMode,
      requireViewerName: this.requireViewerName,
      deactivatedWordsList: this.deactivatedWordsList,
      pausedWordsList: this.pausedWordsList,
      connectedSessionId: this.connectedSessionId
        ? this.connectedSessionId
        : null,
    };
  }
}

interface SessionDTO {
  id: string;
  name: string;
  color: string | null;
  startAt: firebase.firestore.Timestamp;
  endAt: firebase.firestore.Timestamp;
  sessionUnlimited: boolean;
  link: string | null;
  description: string | null;
  createdAt: Date;
  updatedAt: Date;
  author: string;
  authorName: string;
  userGroup: string;
  shareToken: string;
  reactions: Map<string, number>;
  isPaused: boolean;
  deactivatedMenuItems: string[];
  qrCodeImageURL: string;
  labels: { [id: string]: string };
  descriptions: { [id: string]: string };
  hideAllIncomingQuestions: boolean;
  voteResultType: number;
  hidePollLabels: boolean;
  questionsCount: number;
  showQuestionsUserNameInput: boolean;
  hideQuestionLikes: boolean;
  wordcloudTopic: string;
  activeWordcloud: string;
  tv: string;
  moderatorMode: string[];
  requireViewerName: boolean;
  deactivatedWordsList: string[];
  pausedWordsList: PausedWord[];
  connectedSessionId?: string;
}

export default PresenterSession;
